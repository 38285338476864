<template>
  <div class="integrations">
    <div class="integrations-title">
      <h1>
        Integrações
      </h1>
      <h5>
        Para realizar a integração do Eyecare BI com nossos parceiros, insira abaixo o seu respectivo código.
      </h5>
      <hr />
    </div>

    <LabpacsConfig v-if="hasLabpacsIntegrationFeature" />
    <EssilorConfig v-if="hasEssilorIntegrationFeature" />

  </div>
</template>
<script>
import { showFeature } from '@/utils/permissionsHelp'
export default {
  name: 'IntegrationsView',
  components: {
    LabpacsConfig: () => import('./Components/LabpacsConfig'),
    EssilorConfig: () => import('./Components/EssilorConfig')
  },

  computed: {
    hasLabpacsIntegrationFeature() {
      return this.showFeature('Integração com Labpacs')
    },
    hasEssilorIntegrationFeature() {
      return this.showFeature('Integração com Essilor')
    }
  },

  methods: {
    showFeature
  }

}
</script>
<style lang="scss" scoped>
.integrations {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .integrations-title {
    h1 {
      font-size: 1.5rem;
      margin-bottom: 0.5rem;
    }

    h5 {
      font-size: 1rem;
      color: #6c757d;
    }
  }

}


.icon {
  width: 27px;
  height: 27px;
}
</style>